<mat-card 
    appearance="outlined" 
    class="group-platform-card card-display" 
    [title]="cardTitle">
    
    <mat-card-title fxLayoutAlign="space-between center" fxLayout="row">
        <div (click)="cardClicked()" class="text">{{cardTitle}}</div>
        <ng-content select="[card-title-extras]"></ng-content>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <ng-content select="[menu-items]"></ng-content>
        </mat-menu>
    </mat-card-title>

    <div (click)="cardClicked()">
        <ng-content select="[card-content]"></ng-content>
    </div>
    
    <ng-content select="[card-footer]"></ng-content>
    
</mat-card> 