import { Component, OnInit, ViewChild } from "@angular/core";
import {
    AbstractControlOptions,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import {
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";

// store
import * as fromConnect from "app/connect/store";
import * as fromAuth from "app/authentication-v2/store";

// ngrx | rxjs
import { ofType } from "@ngrx/effects";
import { ActionsSubject, Store } from "@ngrx/store";
import { BehaviorSubject, Observable, map, takeUntil } from "rxjs";

// components
import { BaseComponent } from "app/shared/base/base-component";
import { getFormGroup } from "app/shared/components/mobile-phone-entry/mobile-phone-entry.component";
import { ValueChangedEvent } from "app/connect/components/code-entry/code-entry.component";
import { OtpCheckDialogComponent } from "app/authentication-v2/dialogs/otp-check-dialog/otp-check-dialog.component";
import { AuthenticatorAppSetupComponent } from "app/connect/components/authenticator-app-setup/authenticator-app-setup.component";

// utilities
import { RegexPatterns } from "app/shared/utilities/regex-utilities";

// services
import { AlertService } from "app/shared/components/alert/services/alert.service";
import { ModuleService } from "app/shared/services/module.service";

// models
import {
    PasswordValidation,
    UserProfileUpdate,
    UserProfileUpdateResult,
} from "app/authentication-v2/models";
import { OtpCheckDialogData } from "app/authentication-v2/models/otp-check-dialog-data.model";

// enums
import { ContactType } from "app/authentication-v2/enumerations/contact-type.enum";
import { UserAuthenticationMethod } from "app/shared/enums/user-authentication-method.enum";

enum DialogView {
    Profile,
    Password,
    Pin,
    AuthenticatorApp,
}

@Component({
    templateUrl: "user-profile-dialog.component.html",
})
export class UserProfileDialogComponent
    extends BaseComponent
    implements OnInit {
    @ViewChild(AuthenticatorAppSetupComponent, { static: false })
    authenticatorAppSetupComponent: AuthenticatorAppSetupComponent;

    DialogView = DialogView;

    dialogTitle$: Observable<string>;
    dialogSubtitle$: Observable<string>;
    dialogView$ = new BehaviorSubject(DialogView.Profile);

    profileForm: FormGroup;
    passwordForm: FormGroup;
    pinForm: FormGroup;

    constructor(
        private authStore: Store<fromAuth.AuthenticationState>,
        private connectStore: Store<fromConnect.ApplicationState>,
        private actionsSubject: ActionsSubject,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<UserProfileDialogComponent>,
        private dialog: MatDialog,
        private alertService: AlertService
    ) {
        super();
    }

    get canSave() {
        switch (this.dialogView$.value) {
            case DialogView.Profile:
                return this.profileForm.valid;
            case DialogView.Password:
                return this.passwordForm.valid;
            case DialogView.Pin:
                return this.pinForm.valid;
            case DialogView.AuthenticatorApp:
                return this.authenticatorAppSetupComponent?.form.valid;
        }
    }

    ngOnInit() {
        this.createForms();

        this.dialogTitle$ = this.dialogView$.pipe(
            map((dialogView) => {
                switch (dialogView) {
                    case DialogView.Profile:
                        return "Your details";
                    case DialogView.Password:
                        return "Change password";
                    case DialogView.Pin:
                        return "Change pin";
                    case DialogView.AuthenticatorApp:
                        return "Two-Factor Authentication (2FA)";
                }
            })
        );

        this.dialogSubtitle$ = this.dialogView$.pipe(
            map((dialogView) => {
                switch (dialogView) {
                    case DialogView.Profile:
                        return "Edit your personal details";
                    case DialogView.Password:
                        return "Edit your password";
                    case DialogView.Pin:
                        return "Edit your pin";
                }
            })
        );

        this.connectStore
            .select(fromConnect.getUser)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((user) => {
                this.profileForm.patchValue(user);

                this.profileForm
                    .get("mobile")
                    .get("mobileNumber")
                    .setValue(user.mobileNumber);

                this.profileForm
                    .get("mobile")
                    .get("diallingCode")
                    .setValue(user.diallingCode);

                this.profileForm
                    .get("isUsingAuthenticator")
                    .setValue(
                        user.isAuthenticatorSetupComplete &&
                            user.preferredAuthenticationMethod ===
                                UserAuthenticationMethod.AuthenticatorApp
                    );
            });

        this.actionsSubject
            .pipe(
                takeUntil(this.ngUnsubscribe),
                ofType(fromAuth.UpdateUserProfileSuccess)
            )
            .subscribe((action) => {
                const response = action.response;

                if (
                    response.errorUpdatingPassword ||
                    response.errorUpdatingUser ||
                    response.errorUpdatingPin
                ) {
                    this.displayUpdateUserError(response);
                } else {
                    this.alertService.success(
                        "Your account details have been updated."
                    );

                    this.dialogRef.close();

                    if (response.mobileNumberConfirmationId) {
                        this.openOtpDialog(response.mobileNumberConfirmationId);
                    }

                    this.connectStore.dispatch(fromConnect.RehydrateUser());
                }
            });

        this.actionsSubject
            .pipe(
                takeUntil(this.ngUnsubscribe),
                ofType(fromAuth.ConfirmAuthenticatorSetupSuccess)
            )
            .subscribe(() => {
                // Update the user profile to set the new preferred authentication method.
                this.authStore.dispatch(
                    fromAuth.UpdateUserProfile({
                        request: {
                            id: this.profileForm.get("id").value,
                            detailsChanged: false,
                            updatePassword: false,
                            preferredAuthenticationMethod: UserAuthenticationMethod.AuthenticatorApp,
                        },
                    })
                );
            });

        this.actionsSubject
            .pipe(
                takeUntil(this.ngUnsubscribe),
                ofType(fromAuth.UpdateUserProfileFail, fromAuth.ConfirmAuthenticatorSetupFail)
            )
            .subscribe(() => {
                this.alertService.error(
                    "We were unable to update your account details."
                );
            });
    }

    createForms() {
        this.profileForm = this.formBuilder.group({
            id: [null],
            isAuthenticatorEnabled: [false],
            isAuthenticatorSetupComplete: [false],
            isUsingAuthenticator: new FormControl({
                value: false,
                disabled: ModuleService.isSuperUser, // Super users are forced to use an authenticator app
            }),
            firstName: [
                "",
                [
                    Validators.required,
                    Validators.pattern(RegexPatterns.Name),
                    Validators.minLength(2),
                    Validators.maxLength(60),
                ],
            ],
            surname: [
                "",
                [
                    Validators.required,
                    Validators.pattern(RegexPatterns.Name),
                    Validators.minLength(2),
                    Validators.maxLength(60),
                ],
            ],
            email: new FormControl({ value: "", disabled: true }),
            mobile: getFormGroup(this.formBuilder, false),
        });

        this.passwordForm = this.formBuilder.group(
            {
                currentPassword: ["", Validators.required],
                password: [
                    "",
                    [
                        Validators.pattern(RegexPatterns.Password),
                        Validators.required,
                    ],
                ],
                confirmPassword: [
                    "",
                    [
                        Validators.pattern(RegexPatterns.Password),
                        Validators.required,
                    ],
                ],
            },
            <AbstractControlOptions>{
                validators: [PasswordValidation.matchPassword],
            }
        );

        this.pinForm = this.formBuilder.group({
            oldPin: [
                null,
                [Validators.required, Validators.pattern(RegexPatterns.Pin)],
            ],
            newPin: [
                null,
                [Validators.required, Validators.pattern(RegexPatterns.Pin)],
            ],
        });
    }

    onChangePassword() {
        this.dialogView$.next(DialogView.Password);
    }

    onChangePin() {
        this.dialogView$.next(DialogView.Pin);
    }

    onBack() {
        if (this.dialogView$.value === DialogView.AuthenticatorApp) {
            // Reset the authenticator app toggle if the user cancels the setup. 
            this.profileForm.get("isUsingAuthenticator").setValue(false);
        }

        this.dialogView$.next(DialogView.Profile);
    }

    onCurrentPinValueChanged(event: ValueChangedEvent): void {
        this.pinForm.get("oldPin").setValue(event.code?.trim());
    }

    onNewPinValueChanged(event: ValueChangedEvent): void {
        this.pinForm.get("newPin").setValue(event.code?.trim());
    }

    onToggleAuthenticatorApp(checked: boolean) {
        if (!checked) {
            // Do nothing if the user is trying to disable authenticator app.
            // Their preference will be saved when they click save.
            return;
        }

        if (checked && this.profileForm.get("isAuthenticatorSetupComplete").value) {
            // Do nothing if the user is enabling the authenticator app and it's already setup.
            return;
        }

        this.dialogView$.next(DialogView.AuthenticatorApp);
    }

    onSave() {
        if (this.dialogView$.value === DialogView.AuthenticatorApp) {
            this.authenticatorAppSetupComponent.onConfirm();
            return;
        }

        this.authStore.dispatch(
            fromAuth.UpdateUserProfile({ request: this.buildUpdateData() })
        );
    }

    buildUpdateData(): UserProfileUpdate {
        const model = new UserProfileUpdate();
        model.id = this.profileForm.get("id").value;
        model.firstName = this.profileForm.get("firstName").value;
        model.surname = this.profileForm.get("surname").value;
        model.detailsChanged = this.profileForm.dirty;
        model.currentPassword = this.passwordForm.get("currentPassword").value;
        model.newPassword = this.passwordForm.get("password").value;
        model.updatePassword = this.passwordForm.dirty;
        model.oldPin = this.pinForm.get("oldPin").value;
        model.newPin = this.pinForm.get("newPin").value;

        model.preferredAuthenticationMethod = (() => {
            if (!this.profileForm.get("isUsingAuthenticator").dirty) {
                return null;
            }

            return this.profileForm.get("isUsingAuthenticator").value
                ? UserAuthenticationMethod.AuthenticatorApp
                : UserAuthenticationMethod.Pin;
        })();

        model.mobileNumber = this.profileForm
            .get("mobile")
            .get("mobileNumber").value;

        model.diallingCode = this.profileForm
            .get("mobile")
            .get("diallingCode").value;

        return model;
    }

    displayUpdateUserError(result: UserProfileUpdateResult) {
        let errorMessage = "";

        if (result.errorUpdatingPassword) {
            errorMessage = `Unable to reset password: ${result.passwordUpdateMessage}`;
        }

        if (result.errorUpdatingUser) {
            errorMessage = `${errorMessage} ${result.userUpdateMessage}`;
        }

        if (result.errorUpdatingPin) {
            errorMessage = `${errorMessage} ${result.pinUpdateMessage}`;
        }

        this.alertService.error(errorMessage);
    }

    togglePasswordVisibility(element: HTMLElement) {
        if (element.hasAttribute("data-show-password")) {
            element.removeAttribute("data-show-password");
        } else {
            element.setAttribute("data-show-password", "true");
        }
    }

    openOtpDialog(confirmationId: Guid): void {
        this.dialog.open(OtpCheckDialogComponent, {
            disableClose: true,
            data: {
                confirmationId,
                type: ContactType.Phone,
            } as OtpCheckDialogData,
        });
    }
}
