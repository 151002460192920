import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    RouterStateSnapshot,
} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// services
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

// components
import { RouteDisabledDialogComponent } from 'app/shared/components/route-disabled-dialog/route-disabled-dialog.component';

// enums
import {
    ClientUserTokenType,
    getClientUserTokenTypeAllowedRoutes,
} from 'app/shared/enums/client-user-token-type.enum';

@Injectable()
export class AuthGuard implements CanActivateChild, CanActivate {
    constructor(
        private authenticationToken: AuthenticationTokenService,
        private dialog: MatDialog
    ) {}

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        return this.canActivate(route, state);
    }

    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authenticationToken.getClientUserTokenType()) {
            const tokenType = this.authenticationToken.getClientUserTokenType();
            const allowedRoutes =
                getClientUserTokenTypeAllowedRoutes(tokenType);
            const url = state.url.toLowerCase();
            const allowed = (() => {
                switch (tokenType) {
                    case ClientUserTokenType.ClientPortalInviteWizardToken: {
                        return this.hasAccessToRoute(url, allowedRoutes, {});
                    }
                    case ClientUserTokenType.ClientPortalEntityProfileToken: {
                        const metadata =
                            this.authenticationToken.getClientUserTokenMetadata();
                        const entityId = metadata.entityId;

                        return this.hasAccessToRoute(url, allowedRoutes, {
                            entityId,
                        });
                    }
                    default:
                        return true;
                }
            })();

            if (!allowed) {
                this.dialog.open(RouteDisabledDialogComponent);
            }

            return allowed;
        }

        if (this.authenticationToken.isLoggedIn()) {
            return true;
        }

        window.location.href = this.authenticationToken.getLoginUrl();
    }

    hasAccessToRoute(
        route: string,
        allowedRoutes: string[],
        parameters: { [key: string]: string }
    ) {
        const paramitisedRoutes = [];

        for (const allowedRoute of allowedRoutes) {
            const paramatisedRoute = allowedRoute.split('/').map((part) => {
                if (
                    part.startsWith('{') &&
                    part.endsWith('}') &&
                    parameters[part.substring(1, part.length - 1)]
                ) {
                    return parameters[part.substring(1, part.length - 1)];
                }

                return part;
            });

            paramitisedRoutes.push(paramatisedRoute.join('/'));
        }

        if (paramitisedRoutes.includes('*')) {
            return true;
        }

        if (paramitisedRoutes.includes(route)) {
            return true;
        }

        const wildcardRoutes = paramitisedRoutes.filter((paramitisedRoute) =>
            paramitisedRoute.includes('*')
        );

        for (const wildcardRoute of wildcardRoutes) {
            const wildcardSplit = wildcardRoute.split('*');
            const wildcardMatch = route.substring(0, wildcardSplit[0].length);

            if (wildcardSplit[0] === wildcardMatch) {
                return true;
            }
        }

        return false; 
    }
}
