import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// ngrx | rxjs
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';

// store
import * as fromStore from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';
import { UserNotificationsDialogComponent } from 'app/connect/components/user-notifications-dialog/user-notifications-dialog.component';
import { RouteDisabledDialogComponent } from 'app/shared/components/route-disabled-dialog/route-disabled-dialog.component';

// services
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

// models
import { Client } from 'app/models/client.model';
import { User } from 'app/models/user.model';
import { UserGroup } from 'app/models/user-group.model';
import { UserModule } from 'app/models/user-module.model';

// enums
import { getDataAutoModuleName, Modules } from 'app/shared/enums';
import { TooltipPage } from 'app/connect/enums/tooltip-page.enum';
import { ClientUserTokenType } from 'app/shared/enums/client-user-token-type.enum';


@Component({
    selector: 'app-connect-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

    showHeader$: Observable<boolean>;
    logoUrl$: Observable<string>;
    credasLogoColor$: Observable<string>;
    applications$: Observable<UserModule[]>;
    service$: Observable<UserModule>;
    services$: Observable<UserModule[]>;
    currentApplication: UserModule;
    userGroup$: Observable<UserGroup>;
    showHelpFaqs$: Observable<boolean>;
    showTooltipsTour$: Observable<boolean>;
    unreadCount$: Observable<number>;
    showClientLogo$: Observable<boolean>;
    showNavigationMenu$: Observable<boolean>;
    showUserProfileButton$: Observable<boolean>;
    supportUrl$: Observable<string>;
    client: Client;

    userInitials: string;
    userName: string;
    logoUrl: string;

    getDataAutoModuleName = getDataAutoModuleName;

    constructor(
        private store: Store<fromStore.ConnectStoreState>,
        private dialog: MatDialog,
        private authenticationTokenService: AuthenticationTokenService
    ) {
        super();
    }

    ngOnInit(): void {
        this.showHeader$ = this.store.select(fromStore.getShowHeader);
        this.applications$ = this.store.select(fromStore.getApplications);
        this.services$ = this.store.select(fromStore.getServices);
        this.service$ = this.store.select(fromStore.getService);
        this.logoUrl$ = this.store.select(fromStore.getLogoUrl);
        this.credasLogoColor$ = this.store.select(fromStore.getCredasLogoColor);
        this.userGroup$ = this.store.select(fromStore.getUserGroup);
        this.showHelpFaqs$ = this.store.select(fromStore.getShowHelpFaqsOnNavigation);
        this.showTooltipsTour$ = this.store.select(fromStore.getShowTooltipsTourOnNavigation);
        this.showClientLogo$ = this.store.select(fromStore.getShowClientLogo);
        this.showNavigationMenu$ = this.store.select(fromStore.getShowNavigationMenu);
        this.showUserProfileButton$ = this.store.select(fromStore.getShowUserProfileButton);

        this.unreadCount$ = combineLatest([
            this.store.select(fromStore.getUnreadNotificationsCount),
            this.store.select(fromStore.getUnreadAlertsCount),
        ]).pipe(
            filter(
                ([notifications, alerts]) =>
                    typeof notifications === 'number' &&
                    typeof alerts === 'number'
            ),
            map(([notifications, alerts]) => notifications + alerts)
        );

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getApplication))
            .subscribe(application => {
                this.currentApplication = application;
            });

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getLogoUrl))
            .subscribe((logoUrl: string) => {
                this.logoUrl = logoUrl;
            });

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getClient))
            .subscribe((client: Client) => {
                this.client = client;
            });

        this.supportUrl$ = this.store.select(fromStore.getClient).pipe(
            takeUntil(this.ngUnsubscribe),
            filter(client => !!client),
            map(client => client.branding?.supportUrl)
        );

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromStore.getUser))
            .subscribe((user: User) => {
                if (user) {
                    this.userInitials = `${user.firstName[0]}${user.surname[0]}`.toUpperCase();
                    this.userName = `${user.firstName} ${user.surname}`;
                } else {
                    this.userInitials = null;
                    this.userName = null;
                }
            });

        combineLatest([
            this.store.select(fromStore.getOpenNotificationsPanel),
            this.store.select(fromStore.getNotifications),
            this.store.select(fromStore.getUserAlerts)
        ])
            .pipe(
                takeUntil(this.ngUnsubscribe),
                filter(([openNotifications, notifications, alerts]) => openNotifications &&
                    (notifications?.length > 0 || alerts?.length > 0)),
                first(),
            )
            .subscribe(() => this.onNotificationBellClick());
    }

    onApplicationClick(applicationId: Modules): void {
        this.store.dispatch(fromStore.SelectApplication({ applicationId, performRedirect: true }));
    }

    onApplicationChange(value: any): void {
        const tokenType = this.authenticationTokenService.getClientUserTokenType();
        if (tokenType && tokenType !== ClientUserTokenType.ClientPortalEntityModuleToken) {
            this.dialog.open(RouteDisabledDialogComponent);
        } else {
            const applicationId = Number(value);
            this.store.dispatch(fromStore.SelectApplication({ applicationId, performRedirect: true }));
        }
    }

    onUserMenuClick(): void {
        const tokenType = this.authenticationTokenService.getClientUserTokenType();
        if (tokenType && tokenType !== ClientUserTokenType.ClientPortalEntityModuleToken) {
            this.dialog.open(RouteDisabledDialogComponent);
        } else {
            this.store.dispatch(fromStore.OpenUserMenu());
        }
    }


    onServiceClick(serviceId: Modules): void {
        const tokenType = this.authenticationTokenService.getClientUserTokenType();
        if (tokenType && tokenType !== ClientUserTokenType.ClientPortalEntityModuleToken) {
            this.dialog.open(RouteDisabledDialogComponent);
        } else {
            this.store.dispatch(fromStore.SelectService({ serviceId, performRedirect: false }));
        }
    }

    onLogoClicked(): void {
        if (
            [ClientUserTokenType.EntityPortalMagicLinkToken]
                .includes(this.authenticationTokenService.getClientUserTokenType())
        ) {
            return;
        }
        const tokenType = this.authenticationTokenService.getClientUserTokenType();
        if (tokenType) {
            this.dialog.open(RouteDisabledDialogComponent);
        } else if (this.client) {
            // Check for a client, if we have one, re-select it, this will force the default APP for clients to load
            this.store.dispatch(fromStore.SelectClient({ clientId: this.client.id, performRedirect: true, setServiceFromUrl: false }));
        } else {
            // If there is no client, then the user MUST be an "Entity" user. So just fire a refresh to refresh the homepage
            window.location.href = window.location.href; // eslint-disable-line no-self-assign
        }
    }

    onOpenHelpFaqSearchClick(): void {
        const tokenType = this.authenticationTokenService.getClientUserTokenType();
        if (tokenType && tokenType !== ClientUserTokenType.ClientPortalEntityModuleToken) {
            this.dialog.open(RouteDisabledDialogComponent);
        } else {
            this.store.dispatch(fromStore.OpenHelpFaqSearch());
        }
    }

    //Method added not to redirect to that help text url scenario based on the event.
    onOpenHelpFaqSearchNotClick(event: Event): void {
        const tokenType = this.authenticationTokenService.getClientUserTokenType();
        if (tokenType && tokenType !== ClientUserTokenType.ClientPortalEntityModuleToken) {
            this.dialog.open(RouteDisabledDialogComponent);
            event.stopPropagation();
            event.preventDefault();
        }
    }

    onShowTooltipsTourClick(): void {
        this.store.dispatch(fromStore.SetTooltipsIsTour({ isTour: true }));
        this.store.dispatch(fromStore.GetTooltips({ page: TooltipPage.ClientPortalPage, isTour: true }));
    }

    onNotificationBellClick() {
        const tokenType = this.authenticationTokenService.getClientUserTokenType();
        if (tokenType && tokenType !== ClientUserTokenType.ClientPortalEntityModuleToken) {
            this.dialog.open(RouteDisabledDialogComponent);
        } else {
            this.dialog.open(UserNotificationsDialogComponent);
        }
    }

}