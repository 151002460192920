import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// components
import { BaseComponent } from 'app/shared/base/base-component';

//services
import { BlobService } from 'app/shared/services/blob.service';

// utilities
import { getDataFromBase64File } from 'app/shared/utilities/string-utilities';

import { FileSaverService } from 'ngx-filesaver';

@Component({
    templateUrl: './image-dialog.component.html',
})
export class ImageDialogComponent extends BaseComponent {
    imageBase64: string;
    title: string;
    contentType: string;
    
    constructor(@Inject(MAT_DIALOG_DATA) data: any, private fileSaverService: FileSaverService) {
        super();
        this.title = data.title;
        this.imageBase64 = data.imageBase64;
        this.contentType = data.contentType;
        }

    onDownloadFile() {
        const dataDownload = BlobService.base64toBlob(getDataFromBase64File(this.imageBase64), this.contentType)
        this.fileSaverService.save(dataDownload, this.title, this.contentType);
    }
}
