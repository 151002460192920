import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// ngrx | rxjs
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// components
import { BaseComponent } from 'app/shared/base/base-component';
import { BrandPanelComponent } from 'app/connect/components/brand-panel/brand-panel.component';
import { ImagePopupComponent } from 'app/connect/components/image-popup/image-popup.component';

// store
import * as fromConnect from 'app/connect/store';

// models
import { HelpFaq } from 'app/connect/models/help-faq.model';
import { HelpFaqSummary } from 'app/connect/models/help-faq-summary.model';
import { HelpFaqStep } from 'app/connect/models/help-faq-step.model';
import { ImagePopupData } from 'app/shared/models/image-popup-data.model';

// enums
import { TooltipPage } from 'app/connect/enums/tooltip-page.enum';

@Component({
    selector: 'app-help-faq-search',
    templateUrl: './help-faq-search.component.html',
    styleUrls: ['./help-faq-search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HelpFaqSearchComponent extends BaseComponent implements OnInit {
    @ViewChild(BrandPanelComponent) brandPanelComponent: BrandPanelComponent;

    visible: boolean;
    loading$: Observable<boolean>;

    helpFaqs: HelpFaqSummary[];
    selectedHelpFaq: HelpFaq;
    selectedHelpFaqStep: HelpFaqStep;
    
    private searchTimer: NodeJS.Timeout;

    constructor(
        private connectStore: Store<fromConnect.ConnectStoreState>,
        private dialogs: MatDialog,
    ) {
        super();
    }

    ngOnInit(): void {
        this.loading$ = this.connectStore.select(fromConnect.getLoading);

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getShowHelpFaqSearch))
            .subscribe((visible: boolean) => {
                this.visible = visible;

                if (visible) {
                    this.connectStore.dispatch(fromConnect.GetHelpFaqs({ onlyFaqs: true }));
                    this.connectStore.dispatch(fromConnect.GetTooltips({ page: TooltipPage.ClientPortalHelpFaqsSearch }));
                }
            });

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getHelpFaqs))
            .subscribe((helpFaqs: HelpFaqSummary[]) => {
                this.helpFaqs = helpFaqs;
            });

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getHelpFaq))
            .subscribe((helpFaq: HelpFaq) => {
                this.selectedHelpFaq = helpFaq;
            });        
    }

    handleSearch(searchTerm: string): void {
        searchTerm = searchTerm.trim();

        if (!searchTerm) {
            return;
        }

        clearTimeout(this.searchTimer);

        this.searchTimer = setTimeout(() => {
            this.connectStore.dispatch(fromConnect.SearchHelpFaqs({ searchQuery: searchTerm }));
        }, 1000);
    }

    handleHelpFaqSelected(helpFaq: HelpFaqSummary): void {
        this.connectStore.dispatch(fromConnect.GetHelpFaq({ id: helpFaq.id }));

        if (!this.brandPanelComponent.expanded) {
            this.connectStore.dispatch(fromConnect.GetTooltips({ page: TooltipPage.ClientPortalHelpFaqsSearch }));
        }

        this.brandPanelComponent.expanded = true;
    }

    showHelpFaqStep(helpFaqStep: HelpFaqStep): void {
        this.selectedHelpFaqStep = helpFaqStep;

        this.dialogs.open(ImagePopupComponent, {
            data: new ImagePopupData(`api/v2/help-faq-steps/${helpFaqStep.id}/image`, `Step ${helpFaqStep.stepNumber}`, false, helpFaqStep.description)
        });
    }

    closeBrandPanel(): void {
        this.connectStore.dispatch(fromConnect.CloseHelpFaqSearch());
        this.connectStore.dispatch(fromConnect.ResetHelpFaqSearchItems());
        this.selectedHelpFaq = null;
    }

    brandPanelExpand(expanded: boolean) {
        if (expanded) {
            this.connectStore.dispatch(fromConnect.GetTooltips({ page: TooltipPage.ClientPortalHelpFaqsSearch }));
        }
    }

    redirectToSupport() {
        window.open('https://partnersupport.credas.com');
    }
}