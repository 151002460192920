<div class="image-card">
    <mat-card appearance="outlined" *ngIf="src">
        <mat-card-content>
            <div  class="image" fxAlign="center center">
                <img [ngClass]="{ 'greyscale': greyscale }"[src]="src | safe: 'resourceUrl'" />
            </div>
        </mat-card-content>
        <mat-card-footer *ngIf="canRemove || canDownload">
            <div fxLayout="row" fxLayoutAlign="end center"> 
                <button mat-icon-button *ngIf="canDownload" title="Download" (click)="downloadClicked()">
                    <mat-icon aria-label="Download">get_app</mat-icon>
                </button>
                <button mat-icon-button title="Delete" *ngIf="canRemove" aria-label="Delete" (click)="removeClicked()"> 
                    <mat-icon>delete</mat-icon>
                </button>
          </div>
        </mat-card-footer>
    </mat-card>
</div>