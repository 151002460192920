import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// ngrx | rxjs
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// store
import * as fromConnect from 'app/connect/store';

// services
import { ModuleService } from 'app/shared/services/module.service';

// models
import { DataTransferImport } from 'app/shared/models/data-transfer-import.model';
import { Client } from 'app/models/client.model';

// enums
import { FileExtensions } from 'app/shared/enums/file-extensions.enum';
import { getDtoItemTypeDescription } from 'app/shared/enumerations/dto-item-type.enum';

@Component({
    templateUrl: './dto-upload-dialog.component.html',
    styleUrls: ['./dto-upload-dialog.component.scss']
})
export class DtoUploadDialogComponent {
    file: File;
    isSuperUser = ModuleService.isSuperUser;
    importAsPublic = false;
    allowedFileExtensions = [FileExtensions.Zip];
    itemTypeTitle: string;
    privateAllowed = false;
    publicAllowed = false;
    client$: Observable<Client>;
    message: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        connectStore: Store<fromConnect.ConnectStoreState>,
        private dialogRef: MatDialogRef<DtoUploadDialogComponent>) {
            this.itemTypeTitle = getDtoItemTypeDescription(data.itemType);
            this.message = `Import a new ${this.itemTypeTitle}`;
            this.publicAllowed = data.publicAllowed === undefined ? true : data.publicAllowed;
            this.privateAllowed = data.privateAllowed === undefined ? true : data.privateAllowed;
            if (!this.privateAllowed && this.publicAllowed) {
                this.importAsPublic = true;
            }
            this.client$ = connectStore.select(fromConnect.getClient);
        }

    handleFileSelected(file: File): void {
        this.file = file;
    }

    handleOkClicked(): void {
        const dataTransferImport: DataTransferImport = {
            file: this.file,
            importAsPublic: this.importAsPublic
        };

        this.dialogRef.close(dataTransferImport);
    }

    setImportAsPublic(importAsPublic: boolean): void {
        if (importAsPublic && this.publicAllowed) {
            this.importAsPublic = true;
        } else if (!importAsPublic && this.privateAllowed) {
            this.importAsPublic = false;
        }
    }
}
